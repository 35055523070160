<template>
  <div class="ma-4">
    <p class="text-h5">
      {{ message }}
    </p>
    <p
      class="text-subtitle1"
      v-show="redirectMessage"
    >
      {{ redirectMessage }}
    </p>
    <p v-if="verified">
      <router-link to="/login">
        Go to Login
      </router-link>
    </p>
  </div>
</template>

<script>
import { createOrUpdate } from "@/utils/api-request"; 

export default {
  name: "VerifySignup",
  data () {
    return {
      message: "",
      redirectMessage: "",
      verified: false
    }
  },
  mounted () {
    const _token = this.$route.query.token
    const _email = this.$route.query.email
    if (_token && _email) {
      this.verifyTheToken({token: _token, email: _email})
    } else {
      this.$router.push("/signup")
    }
  },
  methods: {
    async verifyTheToken({token, email}) {
      try {
        const response = await createOrUpdate({
          path: "v1/verify-signup-token",
          data: {
            token: token,
            email: email
          },
        });
        this.message = response.data.msg
        this.redirectMessage = "Redirecting to login page."
        if (response && response.status == 200) {
          this.verified = true
          setTimeout(() => {
            this.$router.push("/login")
          }, 3000)
        }
      } catch (e) {
        console.log(e.response.data)
        this.message = e.response.data.msg
        this.redirectMessage = "Redirecting to signup page."
          setTimeout(() => {
            this.$router.push("/signup")
          }, 2000)
      }
    }
  }
}
</script>